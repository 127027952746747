import { useAppSelector } from "store";
import Link from "next/link";
import dynamic from 'next/dynamic'
import { useEffect } from "react";


const Login = dynamic(() => import("components/sample-forms/login"));

const Index: React.FC = () => {
  const config = useAppSelector((state) => state.config);
  const { name } = config;

  useEffect(() => {
    localStorage.removeItem("USER_STORE")
  }, [])


  return (
    <div className="flex flex-row w-full h-screen overflow-hidden text-gray-900 dark:text-white">
      <div className="items-center justify-center hidden w-1/2 border-r border-white-100 dark:border-white-800 lg:flex lg:flex-col bg-white-50 dark:bg-white-800">
        <img
          className="object-contain"
          src="/images/koperasi-id.png"
          alt="jpg"
          style={{ maxWidth: "80%" }}
        />
      </div>
      <div className="flex flex-col items-start justify-center w-full p-4 bg-white dark:bg-gray-900 lg:w-1/2 lg:px-24">
        <div className="items-center justify-center">
          <img
            className="object-contain justify-center"
            src="/images/ASIK.png"
            alt="jpg"
            style={{ maxWidth: "30%" }}
          />
        </div>
        <div className="flex flex-col w-full mb-4">
          {/* <div className="text-sm font-light text-gray-500 uppercase">
              Login
            </div> */}
          <div className="text-sm font-bold">
            Masukkan username dan password anda
          </div>
        </div>
        <Login />

        {/* <div className="w-full mt-4">
            <span>
              <Link href="/forgot-password">
                <a className="text-blue-500">Lupa password?</a>
              </Link>
            </span>
          </div> */}
        <div className="flex flex-col w-full mt-4">
          {/* <div className="flex flex-row space-x-2">
              <Link href="/privacy-policy">
                <a>Privacy policy</a>
              </Link>
              <Link href="/terms-of-service">
                <a>Terms of service</a>
              </Link>
              <Link href="/contact-us">
                <a>Contact us</a>
              </Link>
            </div> */}
          <div className="mt-auto text-gray-300">&copy; {name} 2023</div>
        </div>
      </div>
    </div>
  );
};

export default Index;